:root {
    /*Margins & Paddings*/
    --padding: 25px;
    --margin: 1em;
    --vertical-padding: 25px; /* must NOT be a percentage value */
    --vertical-margin: 2em;  /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 70px;
    --header-min-height: 70px;
    /*Fonts*/
    --base-font-family: 'Roboto', sans-serif;
    --alternative-font-family: 'Bitter', sans-serif;
    --base-font-weight: 300;
    --alternative-font-weight: 400;
    /*Colors*/
    --map-marker-color: 000;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/theme/intendit/css/modules/flex-grid.css";   
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/theme/intendit/css/modules/hoverboxes.css";   
@import "/fonts/Quicksand/all.css";  
@import "/fonts/Roboto/all.css";  
@import "/fonts/Bitter/all.css";  
/*
@import "/css/pikaday.css";
@import "/cssmodules/animate.css";
*/


/*languages*/
li.localeswitcher  {display:none;} 
.localeswitcher.active {display:none;}
header nav ul.menu > li.localeswitcher { border:none; }
nav ul.menu li.localeswitcher a { padding: 0; margin: 0 0 -8px;}
li.localeswitcher img {border-radius:50%; display:block; }



 
/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/
body {color:#353535; font-family: var(--base-font-family); font-weight: var(--base-font-weight); line-height:1.2; 
    border-top: 120px solid transparent; 
 background: url('/thumbs/100x100r/theme/intendit/graphics/bg.png') fixed;  }

/*wrappers*/
main.wrapper {min-height: calc(100vh - var(--header-min-height)); position:relative; }  
.content-wrapper {padding: 40px 0 50px; } 
.content-wrapper > div {padding: 40px  ; background:#fff;  border: 1px solid #e4e4e4; } 

.container {width:100%; }
.container.sml {max-width: 900px;}
.container.lrg {max-width: 1400px;}

/*basic styles*/
a, a:hover {color:#404040; font-weight: var(--base-font-weight); text-decoration: none;}
p {margin:0.2em 0 1.2em;}
strong {font-weight:500;}
iframe {max-width:100%;}
 
/*------------------------------------------------------------------------------------------------ 
LISTS & TABLES ---------------------------------------------------------------------------------*/
.content-wrapper ul {list-style:disc; padding: 0 0 0 15px; margin:0;}
.content-wrapper ul li {margin-bottom:0.2em;}
.content-wrapper ul ul {list-style:none; padding: 0 ; margin:0;}


/*.content-wrapper ul {list-style:none; padding: 0; margin: 0.5em 0;}
.content-wrapper ul li {width: 48%; margin:0 2% 2% 0; float:left;} 
.content-wrapper ul li:before {content: "\2714"; margin:0 0.2em 0 0; font-size:1.2em; }*/

/*
.tjanster .tjanst  ul {list-style:none; padding: 0; margin:  0;}
.tjanster .tjanst li {  padding: 0; margin:0;}*/



/*table
table {font-size:95%; width:100%;}
table tr {border-bottom: 1px solid #e4e4e4;}
table tr td {padding:5px 20px 5px 0; vertical-align: top; border:none; } 
.table-wrapper {display:table; width:100%; }  
.table-wrapper .fa-icon { height: 25px; width:100px; display:block; margin: 0 auto; background:#d0d0d0; fill:#fff; border-radius:40px;  }
.table-wrapper h3 {width:100%; margin-top: 10px; }*/

/*egna klasser*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);} 
.publishdate {color:#909090; font-size:95%; display:block; clear:both;  }
.record { border-bottom:1px solid #e4e4e4;}
 
/*------------------------------------------------------------------------------------------------ 
IMAGE VIDEO FILELIST-----------------------------------------------------------------------------------------*/
img {max-width: 100%; height: auto;}
figure {margin:0; }
.flex > * > img {flex: 0 0 auto;}
.flex > * > img:not([class]) {width: 100%;}
/*artikelbild*/
figure.page-image {float:right; max-width:40%; height:auto; margin: 0 0 2em 2em;}
figure.page-image img {display:block; width:100%; height:auto;}
figure.page-image figcaption {font-style:italic; color:#606060;} /*bildtext inne på sidor*/ 
/*video bakgrundsbild*/
.listing-video {background-size:cover; background-position:center center; min-height:200px; }
/*filelist*/ 
a.file {display:block;}


.medarbetare img {filter: grayscale(100%);}
.medarbetare h2 {font-size: 1.4em; }


.personal .medarbetare {background:#fff; box-shadow: 0px 0px 0px 1px #e4e4e4; }

/*------------------------------------------------------------------------------------------------ 
BUTTONS & ICONS --------------------------------------------------------------------------------*/
.button.button, .readmore {margin:0; padding: 6px 0 8px; display:block; width:100%; text-align: center; border:none; border-radius:2px; 
background: rgba(0, 0, 0, 0.1);  transition:background 0.5s ease-in 0s; }
.contact-banner .button.button {background: rgba(255, 255, 255, 0.1);}  
.readmore {max-width:120px; margin: 5px 0 0; background: #4694ae; border-radius: 40px; color:#fff; } 
.button.button:hover, .readmore:hover { background:rgba(0, 0, 0, 0.3); color:#fff; } 
.box .readmore { margin: 5px auto;  }


/*ikoner*/
.fa-icon {height:20px; width: auto; vertical-align:middle !important; }
.round {background: rgba(0, 0, 0, 0.5); border-radius: 50%; display: inline-block; height: 40px; width: 40px; margin: 0 10px 10px 0; }
.round .fa-icon {fill:#fff; padding: 8px 0; height:40px; width: auto; display:block; margin: 0 auto; }
.round .fa-icon[aria-label="Icon depicting mobile"] {padding: 3px 0; }

.toptext .round {display:block; }
.box .round {display:block; margin:0 auto 1em; height: 90px; width: 90px; background:#01385E;} 
.box .round .fa-icon { padding: 24px 0; height:90px; }

.listing-video .fa-icon {height: 40px; fill:#fff;}

/*------------------------------------------------------------------------------------------------ 
FONTS ----------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {letter-spacing:0; line-height:1.1; padding: 0; margin: 0; font-family: var(--alternative-font-family); font-weight: var(--alternative-font-weight); }
h1{font-size: 2em; margin: 0 0 0.5em; /*text-transform:uppercase;*/}
h1.twelve {max-width:100%;margin: 0 1em .1em; }
h2{font-size: 1.65em;}
h3{font-size: 1.3em;}  
h4, h5, h6 {font-size: 1.3em; }

h2.highlight {font-size:1.5em; padding: 0 0 0.5em; color:#909090; font-weight:400; }

/*------------------------------------------------------------------------------------------------ 
TOPPEN ----------------------------------------------------------------------------------------*/
.toptext { position:absolute; right:10px; top:90px; z-index:11; display:none; } /*display none by default*/
.imagetop {min-height:50vh; background-position: center top; background-size:cover; position: relative;}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/
/*slick slider*/
.slick-wrapper {min-height:50vh; flex-wrap: wrap; position:relative; /*width:100%; top: var(--header-min-height);*/ }
.slick-track, .slick-substitute {display:flex; flex-direction:row;}
.slick-slider, .slick-substitute > div, .slick-slide {min-height:50vh; margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { top:0; left:0; bottom:0; right:0; width:100%; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center top; display:flex !important;}   
.slick {visibility: hidden;} .slick-initialized {visibility: visible;} .slick-list, .slick-track {height:100%;}

 
/*Starttext - aktivera nedersta för starttext inuti slick-wrapper*/ 
.starttext { padding: 30px ; background:#4694ae; color:#fff; max-width: calc(100% - 100px);  position:relative; margin: -80px auto 0; text-align:center; z-index: 0;  
    box-shadow:1px 1px 5px rgba(0, 0, 0, 0.3); text-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

.starttext h1 {margin: 0; font-size: 2.8em;}
.starttext p {margin-bottom:0; font-size: 1.3em; line-height:1.3; } 
/*.slick-slider, .slick-substitute > div { position:absolute; width:100%; height:100%;  top:0; left:0; z-index:-1; }*/

/*Slick arrows*/
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:0px;} .slick-next {right:0px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";} 

 
/*----------------------Boxar-------------------------------*/
.boxes {padding: 1em 0 2em; }
.box { box-shadow: 0px 0px 0px 1px #e4e4e4;  background:#fff;  }
.box h3 { text-align:center; color:#202020; }
.box-text {}
.box-text p:last-of-type { margin:0.2em 0 0.5em;}

/*------------------------------------------------------------------------------------------------ 
CONTENT----------------------------------------------------------------------------------------*/

/*box title decoration*/
.box hr, .tjanst hr { margin-top: 0px; color:#d2d2d2;  margin-bottom: 0px;     display:flex;  align-items: left; }
hr.lastone {  margin-bottom: 10px;}
.box h3 {padding: 9px 0 6px; color: #3b4754 ; text-transform: uppercase; }
.tjanst h3 {padding: 9px 0 6px; color: #3b4754 ; text-transform: uppercase; }
.medarbetare h3 {padding: 9px 0 6px; color: #3b4754 ; text-transform: uppercase; }

.tjanster .tjanst {background:#fff; box-shadow: 0px 0px 0px 1px #e4e4e4; }
 
/*gallery*/
.image-gallery, .logotype-gallery {padding: 2em 0 0; margin-top:1em; border-top: 1px solid #d0d0d0; }
.gallery-image {box-shadow: 1px 1px 0 0 #e4e4e4;} .gallery-image:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.gallery-image a {display:block; }
.gallery-image img {padding:20px; display: block;}
.thumb-title {font-size:90%; padding: 0 20px 20px; display:none; }  

/*.presslink,.presslink:hover {color:#fff;  }
.presslink .fa-icon {height:16px; vertical-align: bottom !important;}*/
 

/*logo gallery*/
.logotype-image {min-height: 70px; } 
.logotype-image div, .logotype-image a {text-align:center; width: 100%; flex:0 0 auto; display:block;} 
.logotype-image img {height:auto;} .standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}  .mfp-arrow {top:40%; } 
.mfp-arrow, .mfp-arrow:before, .mfp-arrow-left:before, .mfp-arrow-right:before,.mfp-arrow:after, .mfp-arrow-left:after, .mfp-arrow-right:after {border:none ; margin-left:0; margin-right:0;  }
.mfp-arrow-left:before {content:"<"; } .mfp-arrow-right:before {content:">"; }
.mfp-arrow-left:before, .mfp-arrow-right:before {height:100px; width:100px; color:#fff; font-size:5.5em; font-family: 'Quicksand', sans-serif; opacity:0.9;}
.mfp-arrow-left {margin-left:20px; }  .mfp-arrow-right {margin-right:20px; } 
button.mfp-close { font-size: 4em; margin:0 -2px 0 0; opacity: 0.9; font-family: Quicksand; }
  
/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/*kontaktbanner & formulär*/
.boltforms-row label, .boltforms-row button {font-weight:400;  margin-bottom: 0.2em;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }
.contact-banner {background: #0b2233; padding:50px 0; color:#fff; }
.contact-banner .button, .contact-banner .button:hover  {color:#fff;}
.contact-banner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.contact-form .boltforms-row, .contact-banner .boltforms-row:nth-child(n+4) {width:100%;}

/*karta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}
.markerLabels{ overflow:visible !important;}
.fa-map-marker{ background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
background-size: cover; padding: 10px; background-position: center; }

/*footer*/
footer {padding: 50px 0 120px; width:100%; background:#000;}
footer, footer a, footer a:hover {color:#b0b0b0; }
footer h4 { padding: 0 0 5px; font-size:1.3em; } 
footer .round {background: rgba(255, 255, 255, 0.2);}
footer svg {fill:#fff; }
/*sticky*/
html.sticky {height:100%;} .sticky body {display:flex; flex-direction:column; min-height:100%;}
.sticky main.wrapper {flex: 1 0 auto; min-height:1em; } .sticky header, .sticky footer {flex: 0 0 auto; }

/*intendit*/
.created-by {text-align: center; padding: 8px 0; background:#101010;}
.created-by p, .created-by a {color:#707070; font-size: 0.9em; letter-spacing: 0.5pt; margin: 0;}

/*mot found*/
.pagenotfound {min-height: 100vh; position:relative; text-align:center; font-size:1.1em; }
.pagenotfound .logo img {max-height: 70px; }
.pagenotfound ul {list-style-type:none; padding: 0; margin: 0.5em auto; width:100%; max-width: 550px; }
.pagenotfound li a { border-bottom: 1px dashed #d0d0d0; display: inline-block;  padding: 10px 110px; }


/*------------------------------------------------------------------------------------------------ 
HEADER ----------------------------------------------------------------------------------------*/
header {background:#efdfca; top:0;  z-index:10; left:0; width: 100%; min-height:var(--header-min-height); font-family: var(--base-font-family); position: fixed; border-bottom: 1px saolid #e4e4e4; }
header > div {min-height:inherit; position:relative} 
body.scrolled header {}

/*Logotype*/
.logo { box-sizing:border-box; max-width: calc(100% - 100px);  position: absolute; top: 15px; left: 20px; z-index:11000; }
.logo a, .logo a:hover {padding:0; display:block; font-size:2em; } 
.logo img {max-height:90px; width:auto; display:block; max-width:100%;  }

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0 5px; margin:0; border-right:1px solid #d0d0d0;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 4px; margin:0; letter-spacing:0; /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a {}
nav ul.menu li.dropdown > a:after { content: ">"; transform: rotate(90deg); vertical-align: middle; display: inline-block; font-family: 'Quicksand', sans-serif;  font-size: 1em; margin: 0 0 0 5px;  }
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
nav ul.menu > li:last-child > ul { left: auto;right: 0; text-align:right; }

/*Mobile menu - fixed header*/
@media (max-width: var(--breakpoint-menu)){
header { position:fixed; min-height:70px; box-shadow:none; flex-wrap:nowrap; border-bottom: 1px solid #e4e4e4;} /*background på header bar*/
header.flex {flex-wrap: no-wrap; }
body {border-top: 70px solid transparent;}   
nav ul.menu.show { max-height:calc(100vh - var(--fixed-header-height)); padding: 10px 0; background:#EFDFCA; }  /*räknar höjd - fixed header*/
nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
nav .menu .dropdown ul {padding:0; left:0px;} 
nav ul.menu li.dropdown > a:after {display:none; }
    .logo { 
    position: relative;
    top: auto;
    left: auto;
 }
    nav ul.menu li.localeswitcher a {
padding: 4px 4px;    margin: 0  ;
}
/*menu icon*/
nav #menu-icon {align-self: flex-start; padding: 20px 15px 20px 0;}
nav #menu-icon span, nav #menu-icon:before, nav #menu-icon:after { border-radius:3px; color:#01385E;}
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}   
    
/*Container*/
.container.lrg {width: 96%;}   
header.container.lrg {width: 100%;}   
header > .container.lrg {max-width:100%; width:100%; }  
}
   
/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
    
.content-wrapper > div {
    padding: 40px 20px;}
    
    
/*Startsida*/ 
.starttext {}
.starttext h1 {font-size: 2.3em;}
.starttext p {} 
    
/*Containers*/
.container {width: 96%;}  
header.container {width: 100%;}  
    
/*slick arrows*/   
.slick-prev, .slick-next { height: 70px; width: 70px;}
.slick-prev:before, .slick-next:before { font-size: 70px;}   
    
.box .round {
    height: 70px;
    width: 70px;
}  
.box .round .fa-icon {
    padding: 17px 0;
    height: 70px;
}  
    
.box {box-shadow:none; padding:0} 
.box h3 {   font-size: 1.2em;}  
 body.container.lrg {width:100%; max-width:100%; }
    
    
.tjanster .tjanst {box-shadow:none; padding:0; flex-grow:1; 
} 
    
    
    
    
}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .content-wrapper {background:#fff; }
.content-wrapper > div { padding:0; 
      border: none;
}  
.personal .medarbetare  {  box-shadow:none; }  
    .medarbetare > div {padding: 15px 20px; }  
    
/*Startsida*/ 
.starttext {
    padding: 20px;
     max-width: calc(100% - 40px);
 }
.starttext h1 {font-size:2em;}
.starttext p {font-size: 1.2m;} 
    
/*Fontsize*/ 
body {font-size: 0.95em; }
    
/*Slider & topbild height */ 
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide, .imagetop {min-height:55vw;}
 
.logo img {
    max-height: 70px;}
    
/*gallerithumbs*/
.gallery-image:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.gallery-image:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
figure.page-image {margin: 0 0 1.3em 1.3em;}
    
/*Containers*/
.container.sml {width: 96%;}   
   
/*Footer*/   
footer .s-twelve {border-bottom: 1px solid #222222; margin: 0 auto; max-width: 400px; padding: 20px 0; text-align: center; }
footer .s-twelve:last-child {border-bottom: none; }   
  
/*Not found*/
.pagenotfound li a {padding: 10px 20px; width: 100%;}
    
    
    
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
/*font*/    
h1 {} 

/*main*/
main.wrapper {min-height: 10px;  }  
   
/*Startsida*/ 
.starttext {}
.starttext h1 {font-size:1.8em;}
.starttext p {font-size: 1.1em;} 
    
/*fixa box-marginaler*/
.margin > .twelve, .margin > .m-twelve, .margin > .s-twelve, .margin > .xs-twelve {margin: 0 0 1em; flex-basis: 100%;}
    
/*image*/
figure.page_image {margin: 0 0 10px 10px; padding:0; border:none;  }   
    
/*sidfot*/
footer  {font-size: 1.1em; line-height: 1.5; word-spacing: 2pt; padding: 30px 0 60px; }     
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.contact-banner {padding: 30px 0;}
.contact-banner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.gallery-image:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.gallery-image:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.gallery-image img {padding: 0.8em;}    
    
/*map*/
.map-canvas {width:100%; max-height:20vh;} 
    
/*table
table tr td { width:100% !important; margin: 0 !important; padding:0 !important ; display: inline-block;}
table tr:first-child {}
table tr td:first-child {padding-top:5px !important; }
table tr td:last-child {padding-bottom:5px !important; }*/  
    
/*blogg*/
.blog-taxonomy { padding: 0 0 1.5em; margin: 0 0 1.5em; border-bottom: 2px solid #d0d0d0;}   
.blog-title {order:-1; } .blog-taxonomy {order:0;} .blog-text {order:1; }
.blog-taxonomy > div  {padding: 0 0 2em; border: none; } 
}

@media (max-width: 375px)
{
h1, h2, h3, h4, h5, h6, a {overflow-wrap: break-word; word-wrap: break-word;word-break: break-all;word-break: break-word;}
h1, h2, h3, h4, h5, h6 { hyphens: auto;}
  
/*font*/    
h1 {font-size:1.8em !important; }  

/*start*/
.slick-wrapper .starttext p {display:none;} 
    
/*bild*/
figure.page-image {width:100%; max-width:100%; margin: 0 0 1em; float:none; }     
}

 
/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
